import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import PortalVue from "portal-vue";
import VueLazyload from "vue-lazyload";
import TextareaAutosize from "vue-textarea-autosize";
import { i18n } from "@/localization";
import "@/registerServiceWorker";
import "@/assets/scss/app.scss";
import "@/assets/svg/index";
import "@/assets/rich-text-svg/index";

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(VueLazyload);
//@ts-ignore
Vue.use(TextareaAutosize);

import VueMoment from "vue-moment";
Vue.use(VueMoment);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
