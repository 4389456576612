import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    beforeEnter: guardAuth,
    component: loadView("home"),
    meta: {
      group: ["guest", "auth"],
    },
  },

  /*
    | -----------------
    | Auth  PAGES
    | -----------------
    | 
    */
  {
    path: "/",
    component: loadView("guest/index"),
    children: [
      {
        path: "login",
        name: "login",
        beforeEnter: guardAuth,
        component: loadView("guest/login"),
        meta: {
          title: "Login | Id.me",
          group: ["guest"],
        },
      },
      {
        path: "/password-forgot",
        name: "password-forgot",
        beforeEnter: guardAuth,
        component: loadView("guest/password-forgot"),
        meta: {
          title: "Wachtwoord vergeten | Id.me",
          group: ["guest"],
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        beforeEnter: guardLogout,
        component: loadView("guest/password-reset"),
        meta: {
          title: "Wachtwoord resetten | Id.me",
          group: ["guest"],
        },
      },
      {
        path: "invite",
        name: "invite",
        beforeEnter: guardAuth,
        component: loadView("guest/invite"),
        meta: {
          title: "Invite | Id.me",
          group: ["guest"],
        },
      },
    ],
  },

  {
    path: "/select",
    name: "select",
    component: loadView("auth/select"),
  },
  {
    path: "/workspace-select",
    name: "workspace-select",
    component: loadView("auth/workspace-select"),
    beforeEnter: guardAuth,
    meta: {
      title: "Workspace select | Id.me",
      group: ["auth"],
    },
  },

  /*
    | -----------------
    | DASHBOARD PAGES
    | -----------------
    | 
    */
  {
    path: "/",
    component: loadView("auth/user/index"),
    children: [
      {
        path: "/overview",
        name: "overview",
        component: loadView("auth/user/overview"),
        beforeEnter: guardAuth,
        meta: {
          title: "Overview | Id.me",
          group: ["auth", "workspace"],
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: loadView("auth/user/profile"),
        beforeEnter: guardAuth,
        meta: {
          title: "Profile | Id.me",
          group: ["auth", "workspace"],
        },
      },
      {
        path: "/workspace",
        name: "workspace",
        component: loadView("auth/user/workspace"),
        beforeEnter: guardAuth,
        meta: {
          title: "Workspace | Id.me",
          group: ["auth", "workspace"],
        },
      },
      {
        path: "/dots",
        name: "dots",
        component: loadView("auth/user/dots/index"),
        beforeEnter: guardAuth,
        meta: {
          title: "Dots | Id.me",
          group: ["auth", "workspace"],
          beforeResolve(to: any, from: any, next: any) {
            const workspace: Workspace = store.getters["workspace/viewing"];

            store.dispatch("startLoadingPage");

            next();

            const params: any = { per_page: 9, s: { created_at: "desc", id: "desc" }, q: { and: { workspace_id: workspace.id } } };
            if (to.query.arena_level) {
              params.q.and.arena_level = to.query.arena_level;
            }
            if (to.query.ids) {
              params.q.and.or.ids = to.query.ids;
            }
            store
              .dispatch("dot/index", params)
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
      {
        path: "/dots/:id",
        name: "dots-detail",
        component: loadView("auth/user/dots/detail"),
        beforeEnter: guardAuth,
        meta: {
          title: "Dots detail | Id.me",
          group: ["auth", "workspace"],
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            store
              .dispatch("dot/read", { id: to.params.id })
              .then(() => next())
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
      {
        path: "/hunches",
        name: "hunches",
        component: loadView("auth/user/hunches/index"),
        beforeEnter: guardAuth,
        meta: {
          title: "Hunches | Id.me",
          group: ["auth", "workspace"],
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");
            store
              .dispatch("hunch/reset")
              .then(() => next())
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
      {
        path: "/hunches/:id",
        name: "hunches-detail",
        component: loadView("auth/user/hunches/detail"),
        beforeEnter: guardAuth,
        meta: {
          title: "Hunches detail | Id.me",
          group: ["auth", "workspace"],
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            store
              .dispatch("hunch/read", { id: to.params.id })
              .then(() => next())
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
    ],
  },

  {
    path: "/share/",
    name: "share-hunch",
    component: loadView("share/index"),
    children: [
      {
        path: "hunches/:id",
        name: "share-hunch",
        component: loadView("share/hunch"),
        meta: {
          title: "Share hunch | Id.me",
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            next();

            store
              .dispatch("hunch/readShared", { id: to.params.id })
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
      {
        path: "dots/:id",
        name: "share-dot",
        component: loadView("share/dot"),
        meta: {
          title: "Share dot | Id.me",
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            next();

            store
              .dispatch("dot/readShared", { id: to.params.id })
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
    ],
  },

  {
    path: "/admin/",
    component: loadView("auth/admin/index"),
    children: [
      {
        path: "workspaces",
        name: "admin-workspaces",
        component: loadView("auth/admin/workspaces"),
        beforeEnter: guardAuth,
        meta: {
          title: "Workspaces | Id.me",
          group: ["auth"],
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            next();

            store
              .dispatch("workspace/index")
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
      {
        path: "users",
        name: "admin-users",
        component: loadView("auth/admin/users"),
        beforeEnter: guardAuth,
        meta: {
          title: "Gebruikers | Id.me",
          group: ["auth"],
          beforeResolve(to: any, from: any, next: any) {
            store.dispatch("startLoadingPage");

            next();

            store
              .dispatch("account/index")
              .catch((err: ErrorResponse) => handleUnauthorized(err, next))
              .finally(() => store.dispatch("stopLoadingPage"));
          },
        },
      },
    ],
  },

  {
    path: "/logout",
    name: "logout",
    beforeEnter: guardLogout,
    component: loadView("home"),
  },

  /*
    | -----------------
    | FIXED PAGES
    | -----------------
    | Show the general  pages
    */
  {
    path: "/terms-and-conditions",
    name: "terms",
    beforeEnter: guardAuth,
    component: loadView("home"),
    meta: {
      title: "Terms and conditions | Id.me",
      group: ["guest"],
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    beforeEnter: guardAuth,
    component: loadView("home"),
    meta: {
      title: "Privacy policy | Id.me",
      group: ["guest"],
    },
  },

  /*
    | -----------------
    | ERROR PAGES
    | -----------------
    | Show the general error pages
    */
  {
    path: "/errors/:code",
    name: "error",
    component: loadView("errors/general"),
    meta: {
      title: "Errors | ",
      group: ["auth"],
      beforeResolve(to: any, from: any, next: any) {
        store.dispatch("startLoadingPage");

        next();

        store.dispatch("stopLoadingPage");
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeResolve(async (routeTo: any, routeFrom: any, next: any) => {
  try {
    if (routeFrom.meta && routeFrom.meta.leaveCallback) {
      await routeFrom.meta.leaveCallback(routeTo, routeFrom);
    }

    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.title) {
          document.title = route.meta.title;
        }
        if (route.meta && route.meta.group) {
          document.querySelector("body")?.classList.add(route.meta.group);
        }

        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args: any) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve({ success: true });
            }
          });
        } else {
          resolve({ success: true });
        }
      });
    }
  } catch (error) {
    return;
  }

  next();
});

/**
 * Guard guest middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
async function guardAuth(to: any, from: any, next: any): Promise<void> {
  if (!to.meta.group) {
    console.error("No group assigned to route: ", to);
    return;
  }

  if (!Array.isArray(to.meta.group)) {
    console.error("To meta group is not an Array", to);
    return;
  }
  console.log(to.name);
  console.log(to.meta.group);
  try {
    const auth: Account = await store.dispatch("auth/me");

    if (to.meta.group.includes("guest") && auth && auth.id) {
      if (auth.roles_names.length > 1) {
        return next({ name: "select" });
      }

      if (auth.roles_names.includes("user")) {
        return next({ name: "overview" });
      }

      if (auth.roles_names.includes("administrator")) {
        return next({ name: "admin-workspaces" });
      }

      return next({ name: "error", params: { code: "401" } });
    }

    if (to.meta.group.includes("auth") && (!auth || !auth.id)) {
      return next({ name: "login" });
    }

    if (to.meta.group.includes("workspace")) {
      const currentSubDomain = getSubdomain(window.location.host);

      // If we are not on a sub domain but we have more than 1 workspace to select from
      if (!currentSubDomain && auth.workspaces.length > 1 && to.name !== "workspace-select") {
        return next({ name: "workspace-select" });
      }

      let switchToSubdomain = auth.workspaces[0];
      if (currentSubDomain && (auth.workspaces.includes(currentSubDomain) || auth.roles_names.includes("administrator"))) {
        switchToSubdomain = currentSubDomain;
      }

      if (!switchToSubdomain && !auth.roles_names.includes("administrator")) {
        return next({ name: "error", params: { code: "404" } });
      }

      if (!switchToSubdomain && auth.roles_names.includes("administrator")) {
        return next({ name: "admin-workspaces" });
      }

      // Download workspace to be sure the user has acces to it.
      const workspace: Workspace[] = await store.dispatch("workspace/index", { q: { and: { url: switchToSubdomain } } });

      if (workspace[0]) {
        store.commit("workspace/SET_VIEWING", workspace[0]);

        if (currentSubDomain !== switchToSubdomain) {
          const baseUrlParts = process.env.VUE_APP_WEB_URL.split("://");
          window.location.href = `${baseUrlParts[0]}://${switchToSubdomain}.${baseUrlParts[1]}/overview`;
        }

        return next();
      }

      return next({ name: "error", params: { code: "403" } });
    }
  } catch (e) {
    console.error(e);
    if (to.meta.group.includes("guest") && !to.meta.group.includes("auth")) {
      return next();
    }
    return next({ name: "login" });
  }

  return next();
}

/**
 * Guard logout middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
async function guardLogout(to: any, from: any, next: any): Promise<void> {
  await store.dispatch("auth/logout");

  if (to.name === "password-reset") {
    return next();
  }

  return next({ name: "login" });
}

function loadView(view: string) {
  return () => import(`@/views/${view}.vue`);
}

function handleUnauthorized(error: ErrorResponse, next: any) {
  if (error.status === 401) {
    next({ name: "error", params: { code: "401" } });
  }
}

function getSubdomain(url: string): string | undefined {
  const baseUrlParts = process.env.VUE_APP_WEB_URL.split(".");
  const parts = url.split(".");

  if (baseUrlParts.length === 2) {
    if (parts.length >= 4) {
      return parts[1];
    }

    if (parts.length === 3 && parts[0] !== "www") {
      return parts[0];
    }

    if (parts.length === 3 && parts[0] === "www") {
      return undefined;
    }
  }

  if (baseUrlParts.length === 3) {
    if (parts.length >= 5) {
      return parts[1];
    }

    if (parts.length === 4 && parts[0] !== "www") {
      return parts[0];
    }

    if (parts.length === 4 && parts[0] === "www") {
      return undefined;
    }
  }

  return undefined;
}

export default router;
