import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: AuthState = {
  isLoggedIn: false,
  auth: undefined,
};

const getters: GetterTree<AuthState, RootState> = {
  me(state: AuthState): Account | undefined {
    return state.auth;
  },
};

const mutations: MutationTree<AuthState> = {
  RESET(state: AuthState) {
    state.isLoggedIn = false;
    state.auth = undefined;
  },
  SET_AUTH(state: AuthState, payload: Account) {
    state.auth = payload;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  login({ rootState }, payload: LoginPayload) {
    return rootState.api
      .post("login", payload, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  register({ rootState }, payload: RegisterPayload) {
    return rootState.api
      .post("register", payload, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  passwordForgot({ rootState }, payload: PasswordForgotPayload) {
    return rootState.api
      .post("password/forgot", payload, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  passwordReset({ rootState }, payload: PasswordResetPayload) {
    return rootState.api
      .post("password/reset", payload, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  me({ commit, rootState, state }, dontUseStorage: boolean | undefined) {
    if (state.auth && !dontUseStorage) {
      return new Promise((resolve) => resolve(state.auth));
    }

    return rootState.api
      .get("me", { withCredentials: true })
      .then((response: { data: Account }) => {
        commit("SET_AUTH", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  logout({ commit, rootState }) {
    return rootState.api
      .post("logout", {}, { withCredentials: true })
      .then(() => {
        commit("RESET");

        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
