import { ActionTree, MutationTree, GetterTree } from "vuex";
import qs from "qs";

const initialState: DotState = {
  all: [],
  viewing: undefined,
};

const getters: GetterTree<DotState, RootState> = {
  all(state: DotState): Dot[] {
    return state.all;
  },
  viewing(state: DotState): Dot | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<DotState> = {
  RESET(state: DotState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: DotState, payload: Dot) {
    const index = state.all.findIndex((model: Dot) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      state.all[index] = payload;
    }
  },
  ADD_MODEL(state: DotState, payload: Dot) {
    state.all.push(payload);
  },
  SET_MODELS(state: DotState, payload: Dot[]) {
    state.all = payload;
  },
  SET_VIEWING(state: DotState, payload: Dot) {
    state.viewing = payload;
  },
};

const actions: ActionTree<DotState, RootState> = {
  create({ commit, rootState }, payload: DotPayload) {
    // @ts-ignore
    payload.outline = JSON.stringify(payload.outline);
    return rootState.api
      .post(`dots`, payload, { withCredentials: true, headers: { "Content-Type": "multipart/form-data" } })
      .then((response: { data: Dot }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: DotPayload; id: number }) {
    if (payload.outline) {
      // @ts-ignore
      payload.outline = JSON.stringify(payload.outline);
    }
    return rootState.api
      .put(`dots/${id}`, payload, { withCredentials: true, headers: { "Content-Type": "multipart/form-data" } })
      .then((response: { data: Dot }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: { q: any }) {
    return rootState.api
      .get(`dots?${qs.stringify(payload)}`, { withCredentials: true })
      .then((response: { data: Dot }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`dots/${payload.id}`, { withCredentials: true })
      .then((response: { data: Dot }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ rootState }, payload: { id: number }) {
    return rootState.api
      .delete(`dots/${payload.id}`, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
