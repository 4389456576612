import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: AccountState = {
  all: [],
  viewing: undefined,
};

const getters: GetterTree<AccountState, RootState> = {
  all(state: AccountState): Account[] {
    return state.all;
  },
  viewing(state: AccountState): Account | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<AccountState> = {
  RESET(state: AccountState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: AccountState, payload: Account) {
    const index = state.all.findIndex((model: Account) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      state.all[index] = payload;
    }
  },
  ADD_MODEL(state: AccountState, payload: Account) {
    state.all.push(payload);
  },
  SET_MODELS(state: AccountState, payload: Account[]) {
    state.all = payload;
  },
  SET_VIEWING(state: AccountState, payload: Account) {
    state.viewing = payload;
  },
};

const actions: ActionTree<AccountState, RootState> = {
  create({ commit, rootState }, payload: AccountPayload) {
    return rootState.api
      .post(`accounts`, payload, { withCredentials: true })
      .then((response: { data: Account }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: AccountPayload; id: number }) {
    return rootState.api
      .put(`accounts/${id}`, payload, { withCredentials: true, headers: { "Content-Type": "multipart/form-data" } })
      .then((response: { data: Account }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }) {
    return rootState.api
      .get(`accounts`, { withCredentials: true })
      .then((response: { data: Account }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`accounts/${payload.id}`, { withCredentials: true })
      .then((response: { data: Account }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  invite({ rootState }, payload: AccountInvitePayload) {
    return rootState.api
      .post(`invites`, payload, { withCredentials: true })
      .then((response: { data: Account }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ rootState }, payload: { id: number }) {
    return rootState.api
      .delete(`accounts/${payload.id}`, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
