import { ActionTree, MutationTree, GetterTree } from "vuex";
import qs from "qs";

const initialState: HunchState = {
  all: [],
  viewing: undefined,
  shared: undefined,
};

const getters: GetterTree<HunchState, RootState> = {
  all(state: HunchState): Hunch[] {
    return state.all;
  },
  viewing(state: HunchState): Hunch | undefined {
    return state.viewing;
  },
  shared(state: HunchState): SharedHunch | undefined {
    return state.shared;
  },
};

const mutations: MutationTree<HunchState> = {
  RESET(state: HunchState) {
    state.all = [];
    state.viewing = undefined;
    state.shared = undefined;
  },
  UPDATE_MODEL(state: HunchState, payload: Hunch) {
    const index = state.all.findIndex((model: Hunch) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      state.all[index] = payload;
    }
  },
  ADD_MODEL(state: HunchState, payload: Hunch) {
    state.all.push(payload);
  },
  SET_MODELS(state: HunchState, payload: Hunch[]) {
    state.all = payload;
  },
  SET_VIEWING(state: HunchState, payload: Hunch) {
    state.viewing = payload;
  },
  SET_SHARED(state: HunchState, payload: SharedHunch) {
    state.shared = payload;
  },
};

const actions: ActionTree<HunchState, RootState> = {
  reset({ commit, rootState }, payload: HunchPayload) {
    commit("RESET");
  },
  create({ commit, rootState }, payload: HunchPayload) {
    return rootState.api
      .post(`hunches`, payload, { withCredentials: true })
      .then((response: { data: Hunch }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: HunchUpdatePayload; id: number }) {
    return rootState.api
      .put(`hunches/${id}`, payload, { withCredentials: true })
      .then((response: { data: Hunch }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: { q: any }) {
    return rootState.api
      .get(`hunches?${qs.stringify(payload)}`, { withCredentials: true })
      .then((response: { data: Hunch }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`hunches/${payload.id}`, { withCredentials: true })
      .then((response: { data: Hunch }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  readShared({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`hunches/${payload.id}/shared`, { withCredentials: true })
      .then((response: { data: SharedHunch }) => {
        commit("SET_SHARED", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ rootState }, payload: { id: number }) {
    return rootState.api
      .delete(`hunches/${payload.id}`, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
