import { ActionTree, MutationTree, GetterTree } from "vuex";
import qs from "qs";

const initialState: CommentState = {
  all: [],
};

const getters: GetterTree<CommentState, RootState> = {
  all(state: CommentState): Comment[] {
    return state.all;
  },
};

const mutations: MutationTree<CommentState> = {
  RESET(state: CommentState) {
    state.all = [];
  },
  UPDATE_MODEL(state: CommentState, payload: Comment) {
    const index = state.all.findIndex((model: Comment) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      state.all[index] = payload;
    }
  },
  ADD_MODEL(state: CommentState, payload: Comment) {
    state.all.push(payload);
  },
  SET_MODELS(state: CommentState, payload: Comment[]) {
    state.all = payload;
  },
};

const actions: ActionTree<CommentState, RootState> = {
  create({ commit, rootState }, payload: CommentPayload) {
    return rootState.api
      .post(`comments`, payload, { withCredentials: true })
      .then((response: { data: Comment }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: CommentPayload; id: number }) {
    return rootState.api
      .put(`comments/${id}`, payload, { withCredentials: true })
      .then((response: { data: Comment }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: { q: any }) {
    return rootState.api
      .get(`comments?${qs.stringify(payload)}`, { withCredentials: true })
      .then((response: { data: Comment }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: { id: number }) {
    return rootState.api
      .get(`comments/${payload.id}`, { withCredentials: true })
      .then((response: { data: Comment }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ rootState }, payload: { id: number }) {
    return rootState.api
      .delete(`comments/${payload.id}`, { withCredentials: true })
      .then(() => {
        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
