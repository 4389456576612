import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import modules from "@/store/modules";
import axios from "axios";

Vue.use(Vuex);

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const debug = process.env.NODE_ENV !== "production";
const store: StoreOptions<RootState> = {
  state: {
    version: process.env.VUE_APP_VERSION,
    api,
    page_loading: false,
  },
  modules,
  strict: debug,
  getters: {
    pageLoading(state: RootState): boolean {
      return state.page_loading;
    },
  },
  mutations: {
    PAGE_LOADING(state: RootState) {
      state.page_loading = true;
    },
    PAGE_LOADED(state: RootState) {
      state.page_loading = false;
    },
  },
  actions: {
    reset({ commit }: any) {
      const promises: any = [];
      Object.keys(modules).forEach((moduleName: string) => {
        commit(`${moduleName}/RESET`);
        promises.push(Promise.resolve());
      });

      return Promise.all(promises).then(() => Promise.resolve());
    },
    startLoadingPage({ commit }: any) {
      commit(`PAGE_LOADING`);
    },
    stopLoadingPage({ commit }: any) {
      commit(`PAGE_LOADED`);
    },
  },
};

const rootStore = new Vuex.Store<RootState>(store);

api.interceptors.request.use(
  (res) => {
    if (res.headers["Content-Type"] === "multipart/form-data") {
      const formData = new FormData();

      if (res.method === "put") {
        formData.append("_method", "PUT");
        res.method = "post";
      }

      Object.keys(res.data).forEach((key: string) => {
        const data = res.data[key];
        const mediaKeys = ["media", "image"];

        if ((data && !mediaKeys.includes(key)) || (mediaKeys.includes(key) && data instanceof File)) {
          if (Array.isArray(data)) {
            data.forEach((d: any) => {
              formData.append(`${key}[]`, d);
            });
          } else {
            formData.append(key, data);
          }
        } else if (key === "media" && data && !Object.prototype.hasOwnProperty.call(data, "id")) {
          console.log(data);
          Object.keys(data).forEach((key: string) => {
            formData.append(`media[${key}]`, data[key]);
          });
        } else if (key === "media" && !data) {
          formData.append("media", "");
        } else if (key === "image" && !data) {
          formData.append("image", "");
        }
      });

      res.data = formData;
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  },
);

api.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const errorResponse: ErrorResponse = {
      status: err.response.status,
      errors: [],
    };

    if (!Object.prototype.hasOwnProperty.call(err.response.data, "errors")) {
      const error: ServerError = {
        message: "server_error",
      };
      errorResponse.errors.push(error);

      throw errorResponse;
    }

    errorResponse.errors = err.response.data.errors;

    throw errorResponse;
  },
);

export default rootStore;
